import { createReducer } from "@reduxjs/toolkit";
import { actions as types } from "./actions";
// import {
// 	GetPincodeDetailsResponse,
// } from "../../services/customer/models/GetPincodeDetailsResponse";
import { actionSuccess } from "../helper";
import { GenerateUserConsentOTPResponse } from "../../services/customer/models/GenerateUserConsent";
import { VerifyUserConsentOTPResponse } from "../../services/customer/models/VerifyUserConsent";

export interface State {
    // pincodeDetails: GetPincodeDetailsResponse | null
    generatedUserRequest: GenerateUserConsentOTPResponse | null
    eligibilityResult: VerifyUserConsentOTPResponse | null
}

const initialState: State = {
	// pincodeDetails: null,
	generatedUserRequest: null,
	eligibilityResult: null,
};

export default createReducer(initialState, {
	// [actionRequest(types.FETCH_PINCODE_DETAILS)]: (state) =>
	// {
	// 	state.pincodeDetails = null;
	// },
	// [actionSuccess(types.FETCH_PINCODE_DETAILS)]: (state, action) =>
	// {
	// 	state.pincodeDetails = action.payload;
	// },
	[actionSuccess(types.GENERATE_USER_CONSENT_OTP)]: (state, action) => 
	{
		state.generatedUserRequest = action.payload;
	},

	[actionSuccess(types.VERIFY_USER_CONSENT_OTP)]: (state, action) => 
	{
		state.eligibilityResult = action.payload;
	},
});

import { ApiError } from "./ApiError";
import HttpStatusCode from "./HttpStatusCodes";
import { NetworkErrorCodes } from "./NetworkErrorCodes";
import { ServiceResponse } from "./ServiceResponse";

const responseCodes = {
	SUCCESS: "SUCCESS",
	FAILED: "FAILED",
	UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
	VALIDATION_ERROR: "VALIDATION_ERROR",
};

class ApiErrorHelper 
{
	static getErrorCodeFromStatus(code: number): string 
	{
		switch (code) 
		{
		case HttpStatusCode.FORBIDDEN: {
			return NetworkErrorCodes.FORBIDDEN;
		}
		case HttpStatusCode.AUTHENTICATION_ERROR: {
			return NetworkErrorCodes.UNAUTHORIZED;
		}
		case HttpStatusCode.UNAUTHORIZED: {
			return NetworkErrorCodes.UNAUTHORIZED;
		}
		default: {
			return NetworkErrorCodes.BAD_REQUEST;
		}
		}
	}

	static getErrorFromNetworkError(response: Response) 
	{
		const error = {} as ApiError;
		error.type = "NetworkError";
		error.errors = [
			{
				code: ApiErrorHelper.getErrorCodeFromStatus(response.status),
				message: "",
			},
		];
		return error;
	}

	static getErrorFromServiceError(response: ServiceResponse) 
	{
		const apiError = {} as ApiError;
		apiError.type = "ServiceError";
		apiError.errors = [];

		if (
			response.Code > 0 ||
            response.Code === responseCodes.FAILED ||
            response.Code === responseCodes.UNEXPECTED_ERROR
		) 
		{
			response.Errors?.forEach((error) => 
			{
				apiError.errors.push({
					code: error.Code || responseCodes.UNEXPECTED_ERROR,
					message: error.Message || error.msg,
				});
			});
		}
		if (response.Code === responseCodes.VALIDATION_ERROR) 
		{
			//
		}

		return apiError;
	}

	static getGenericError(message: string) 
	{
		const apiError = {} as ApiError;
		apiError.type = "ServiceError";
		apiError.errors = [{ code: "UNEXPECTED_ERROR", message: message }];
		return apiError;
	}
}

export default ApiErrorHelper;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const pmt = require("formula-pmt");

import CheckingEligibility from "assets/CheckingEligibility";
import CreatingApplication from "assets/CreatingApplication";
import DocumentSigning from "assets/DocumentSigning";

export enum EligibilityCodes {
    PRE_APPROVED = 1,
    PRE_QUALIFIED = 2,
    NEEDS_INSPECTION = 3,
    REJECTED = 4,
}

export const coachingInstitues = {
	ALLEN: "ALLEN",
	AAKASH: "AAKASH",
	OTHER: "OTHER",
};

export const coachingApplicationSteps = [
	{
		icon: CheckingEligibility,
		label: "CHECKING ELIGIBILITY",
	},
	{
		icon: CreatingApplication,
		label: "CREATING APPLICATION",
	},
	{
		icon: DocumentSigning,
		label: "DOCUMENT SIGNING",
	},
];

export const getEligibilityMessage = (code: number) => 
{
	switch (code) 
	{
	case EligibilityCodes.PRE_APPROVED:
		return "you_have_been_pre_approved";
	case EligibilityCodes.PRE_QUALIFIED:
		return "you_have_been_pre_qualified";
	default:
		return "";
	}
};

export const calcEmi = (loanAmount: number, tenure: number) => 
{
	return (Math.round(pmt(0.02, tenure, loanAmount)) * -1).toLocaleString(
		"en-IN",
		{
			currency: "INR",
		},
	);
};

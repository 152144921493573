import React, { useEffect, useState } from "react";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { toUpper } from "lodash";
import moment from "moment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { TextField, Button, CircularProgress } from "@mui/material";

import { removeDoneActions } from "../../../store/ui/actions";
import { CustomerFormData } from "./models/CustomerFormData";
import { EligibilityCodes } from "./helper";
import { RootState } from "../../../config/store";
import {
	actions,
	generateUserConsentOtp,
	resendUserConsentOtp,
	triggerPabblyWebhook,
	verifyUserConsentOtp,
} from "../../../store/customer/actions";
import { emailRegx, mobileRegx, panRegx } from "../../../utils/commonRegExs";

import {
	checkForDoneAction,
	// checkForErrors,
	checkForLoading,
} from "../../../utils/helpers";

import IntLoanDSK from "../../../assets/InterestLoansDSK.webp";
import IntLoanMOB from "../../../assets/InterestLoansMOB.webp";
import Benefit1 from "../../../assets/Benefit_1.svg";
import Benefit2 from "../../../assets/Benefit_2.svg";
import Benefit3 from "../../../assets/Benefit_3.svg";
import CongratulationTick from "../../../assets/CongratulationTick.svg";
import SadFace from "../../../assets/SadFace.svg";
import HappyFace from "../../../assets/HappyFace.svg";
import "./index.scss";

const useStyles = makeStyles((theme: Theme) => ({
	benefit__item: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: "#EAF3FF",
		gap: "1rem",
		padding: "17px 45px 17px 43px",
		fontSize: "14px",
		borderRadius: "10px",
		color: "#002347",
		[theme.breakpoints.down("sm")]: {
			padding: "6px 23px 6px 23px",
			fontSize: "12px",
		},
	},
}));

const mapStateToProps = (state: RootState) => 
{
	const { ui, customer } = state;
	return {
		ui: ui,
		generateOTPLoader: checkForLoading(
			ui.spinners,
			actions.GENERATE_USER_CONSENT_OTP,
		),
		isOTPGenerated: checkForDoneAction(
			ui.doneActions,
			actions.GENERATE_USER_CONSENT_OTP,
		),
		generatedUserRequest: customer.generatedUserRequest,
		eligibilityResult: customer.eligibilityResult,
		resendOTPLoader: checkForLoading(
			ui.spinners,
			actions.RESEND_USER_CONSENT_OTP,
		),
		verifyOTPLoader: checkForLoading(
			ui.spinners,
			actions.VERIFY_USER_CONSENT_OTP,
		),
		isOTPVerified: checkForDoneAction(
			ui.doneActions,
			actions.VERIFY_USER_CONSENT_OTP,
		),
	};
};

const mapDispatchToProps = {
	removeDoneActions,
	generateUserConsentOtp,
	resendUserConsentOtp,
	verifyUserConsentOtp,
	triggerPabblyWebhook,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>

interface CheckboxValues {
    checkedYes: boolean
    checkedNo: boolean
    checkedHaveAllenNo: boolean
    checkedNotHaveAllenNo: boolean
}

const CustomerFormV2: React.FC<ReduxProps> = (props) => 
{
	const classes = useStyles();

	const [otpSent, setOtpSent] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	/**
     * Timer Logic starts
     */
	const [counter, setCounter] = React.useState(0);

	const [currentStepCompleted, setCurrentStepCompleted] = useState(0);
	const [showFormStep2, setShowFormStep2] = useState(false);
	const [checkboxValues, setCheckboxValues] = useState<CheckboxValues>({
		checkedYes: false,
		checkedNo: false,
		checkedHaveAllenNo: false,
		checkedNotHaveAllenNo: false,
	});
	const [showYesOrNoCheckBox, setShowYesOrNoCheckBox] = useState(true);
	const [showAllenNumberCheckBox, setShowAllenNumberCheckBox] =
        useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const { t } = useTranslation();
	const queryParams = queryString.parse(window.location.search);

	useEffect(() => 
	{
		const timer: any =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		return () => clearInterval(timer);
	}, [counter]);
	/**
     * Timer Logic ends
     */

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<CustomerFormData>({
		mode: "onChange",
	});

	const scrollIntoElement = (name: string) => 
	{
		document.getElementById(name)?.scrollIntoView({
			behavior: "smooth",
			inline: "end",
			block: "start",
		});
	};

	const {
		removeDoneActions,
		generateUserConsentOtp,
		resendUserConsentOtp,
		verifyUserConsentOtp,
		generateOTPLoader,
		isOTPGenerated,
		eligibilityResult,
		triggerPabblyWebhook,
	} = props;

	useEffect(() => 
	{
		if (isOTPGenerated) 
		{
			setOtpSent(true);
			setCounter(30);
			//@ts-ignore
			// fbq("track", "LeadAdd");
			removeDoneActions([actions.GENERATE_USER_CONSENT_OTP]);
		}
	}, [isOTPGenerated]);

	useEffect(() => 
	{
		if (props.isOTPVerified) 
		{
			//@ts-ignore
			// fbq("track", "OTPVerified");
			if (eligibilityResult) 
			{
				triggerPabblyWebhook({
					Url: "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTY5MDYzNTA0MzE1MjY0NTUzMjUxMzAi_pc",
					Data: {
						PhoneNumber: getValues("Mobile"),
						LoanApprovalStatus:
                            eligibilityResult?.EligibilityCode ===
                            EligibilityCodes.PRE_APPROVED
                            	? "Pre-Approved"
                            	: "Not Pre-Approved",
					},
				});

				if (
				//If User is Qualified
					eligibilityResult.EligibilityCode ===
                    EligibilityCodes.PRE_APPROVED
				) 
				{
					//@ts-ignore
					fbq("track", "Approved");
					//@ts-ignore
					gtag("event", "conversion", {
						send_to: "AW-10862700578/09KlCPjE7IUYEKLQ3rso",
					});
				}
				else if (
				//If User is Qualified
					eligibilityResult.EligibilityCode ===
                    EligibilityCodes.PRE_QUALIFIED
				) 
				{
					//@ts-ignore
					fbq("trackCustom", "Approved");
					//@ts-ignore
					gtag("event", "conversion", {
						send_to: "AW-10862700578/zk5bCKjI7IUYEKLQ3rso",
					});
				}
				else if (
				//cibil-not
					eligibilityResult.EligibilityCode ===
                    EligibilityCodes.NEEDS_INSPECTION
				) 
				{
					//@ts-ignore
					fbq("trackCustom", "PullError");
					//@ts-ignore
					gtag("event", "conversion", {
						send_to: "AW-10862700578/OuIoCKed7YUYEKLQ3rso",
					});
				} //If User is not qualified
				else 
				{
					//@ts-ignore
					fbq("trackCustom", "NotQualified");
					//@ts-ignore
					gtag("event", "conversion", {
						send_to: "AW-10862700578/OuIoCKed7YUYEKLQ3rso",
					});
				}
			}
			removeDoneActions([actions.VERIFY_USER_CONSENT_OTP]);
		}
	}, [props.isOTPVerified]);

	useEffect(() => 
	{
		if (currentStepCompleted === 1) 
		{
			const timer = setTimeout(() => 
			{
				setShowFormStep2(true);
			}, 0.01);

			return () => 
			{
				clearTimeout(timer);
			};
		}
	}, [currentStepCompleted]);

	useEffect(() => 
	{
		const handleResize = () => 
		{
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => 
		{
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const onSaveUserDetails = (data: CustomerFormData) => 
	{
		//@ts-ignore

		gtag("event", "conversion", {
			send_to: "AW-10862700578/bGN_CKW_7IUYEKLQ3rso",
		});

		//@ts-ignore
		fbq("track", "Lead");

		triggerPabblyWebhook({
			Url: "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTY5MDYzNTA0MzE1MjY0NTUzMDUxMzQi_pc",
			Data: {
				Name: data.Name,
				Email: data.Email,
				PanNumber: data.Pan,
				DateOfBirth: data.DateOfBirth,
				PhoneNumber: data.Mobile,
			},
		});

		generateUserConsentOtp({
			...data,
			UTMParams: searchParams.toString(),
		});
	};

	const onSubmitError = (data: any) => 
	{
		const errors = Object.keys(data);
		if (errors.length > 0) 
		{
			scrollIntoElement(data[errors[0]]?.ref?.name);
		}
	};

	const onVerifyOtp = (data: any) => 
	{
		const { generatedUserRequest } = props;

		if (generatedUserRequest) 
		{
			//@ts-ignore
			gtag("event", "conversion", {
				send_to: "AW-10862700578/DuuvCKmu7IUYEKLQ3rso",
			});

			//@ts-ignore
			fbq("track", "SubmitApplication");

			verifyUserConsentOtp({
				TxnId: generatedUserRequest.OtpTxnId,
				RequestId: generatedUserRequest.RequestId,
				Otp: data.OTP,
			});
		}
	};

	const onResendOTP = (data: any) => 
	{
		const { generatedUserRequest } = props;
		if (generatedUserRequest) 
		{
			resendUserConsentOtp({
				OtpTransactionId: generatedUserRequest.OtpTxnId,
			});
		}
	};

	const onFirstStep = (data: CustomerFormData) => 
	{
		if (data.Mobile) 
		{
			triggerPabblyWebhook({
				Url: "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTY5MDYzNTA0MzE1MjY0NTUzNjUxMzci_pc",
				Data: {
					PhoneNumber: data.Mobile,
					...(queryParams.source && { Source: queryParams.source }),
				},
			});
			setValue("Mobile", data.Mobile);
			setCurrentStepCompleted(1);
		}
	};

	const handleCheckboxChange = (name: keyof CheckboxValues) => 
	{
		setCheckboxValues((prevValues) => 
		{
			// Uncheck the other checkbox when one is checked
			const updatedValues = {
				checkedYes:
                    name === "checkedYes" ? !prevValues.checkedYes : false,
				checkedNo: name === "checkedNo" ? !prevValues.checkedNo : false,
				checkedHaveAllenNo:
                    name === "checkedHaveAllenNo"
                    	? !prevValues.checkedHaveAllenNo
                    	: false,
				checkedNotHaveAllenNo:
                    name === "checkedNotHaveAllenNo"
                    	? !prevValues.checkedNotHaveAllenNo
                    	: false,
			};

			return {
				...prevValues,
				...updatedValues,
			};
		});
	};

	const handleSubmitOfYesOrNo = () => 
	{
		if (checkboxValues.checkedYes) 
		{
			setShowYesOrNoCheckBox(false);
			setShowAllenNumberCheckBox(true);
			triggerPabblyWebhook({
				Url: "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTY5MDYzNTA0MzE1MjY0NTUzMzUxMzAi_pc",
				Data: {
					PhoneNumber: getValues("Mobile"),
					LoanRequired: "Yes",
				},
			});
		}

		if (checkboxValues.checkedNo) 
		{
			window.open("https://www.allen.ac.in/", "_self");
			triggerPabblyWebhook({
				Url: "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTY5MDYzNTA0MzE1MjY0NTUzMzUxMzAi_pc",
				Data: {
					PhoneNumber: getValues("Mobile"),
					LoanRequired: "No",
				},
			});
		}
	};

	const handleSbmitOfAllenNo = () => 
	{
		if (checkboxValues.checkedHaveAllenNo) 
		{
			window.open(
				"https://www.allen.ac.in/apps2324/loanapps/login",
				"_self",
			);
			triggerPabblyWebhook({
				Url: "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTY5MDYzNTA0MzE1MjY0NTUzYzUxMzMi_pc",
				Data: {
					PhoneNumber: getValues("Mobile"),
					AllenRegistered: "Yes",
				},
			});
		}

		if (checkboxValues.checkedNotHaveAllenNo) 
		{
			window.open(
				"https://www.allen.ac.in/apps2324/ccp/register?leadtype=CCP&referral",
				"_self",
			);
			triggerPabblyWebhook({
				Url: "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTY5MDYzNTA0MzE1MjY0NTUzYzUxMzMi_pc",
				Data: {
					PhoneNumber: getValues("Mobile"),
					AllenRegistered: "No",
				},
			});
		}
	};

	const result = !!eligibilityResult;

	if (result) 
	{
		return (
			<div className="result__main">
				<div className="result__content">
					{eligibilityResult.EligibilityCode <=
                        EligibilityCodes.NEEDS_INSPECTION && (
						<div className="result__success">
							{eligibilityResult.EligibilityCode ===
                            EligibilityCodes.PRE_APPROVED ? (
									<>
										<img
											src={CongratulationTick}
											alt="CongratulationTick"
											width={70}
										/>
										<p className="result__title">
                                        Congratulations!
										</p>
										<p style={{ marginTop: "20px" }}>
                                        Your loan is{" "}
											<strong>pre-approved.</strong>
											<br />
                                        Sanction amount between{" "}
											<strong>1 lakh</strong> and{" "}
											<strong>2 lakhs.</strong>
										</p>
										{showYesOrNoCheckBox && (
											<>
												<p className="result__question">
                                                Do you want to proceed for Loan?
												</p>
												<div className="checkbox">
													<label>
														<input
															type="checkbox"
															name="checkedYes"
															checked={
																checkboxValues.checkedYes
															}
															onChange={() =>
																handleCheckboxChange(
																	"checkedYes",
																)
															}
														/>
														<span>Yes</span>
													</label>
													<label>
														<input
															type="checkbox"
															name="checkedNo"
															checked={
																checkboxValues.checkedNo
															}
															onChange={() =>
																handleCheckboxChange(
																	"checkedNo",
																)
															}
														/>
														<span>No</span>
													</label>
												</div>
											</>
										)}
										{showAllenNumberCheckBox && (
											<div className="checkbox checkbox__allen">
												<label>
													<input
														type="checkbox"
														name="checkedHaveAllenNo"
														checked={
															checkboxValues.checkedHaveAllenNo
														}
														onChange={() =>
															handleCheckboxChange(
																"checkedHaveAllenNo",
															)
														}
													/>
													<span>
                                                    I have Allen form number
													</span>
												</label>
												<label>
													<input
														type="checkbox"
														name="checkedNotHaveAllenNo"
														checked={
															checkboxValues.checkedNotHaveAllenNo
														}
														onChange={() =>
															handleCheckboxChange(
																"checkedNotHaveAllenNo",
															)
														}
													/>
													<span>
                                                    I do not have Allen form
                                                    number
													</span>
												</label>
											</div>
										)}
										<Button
											onClick={handleSubmit(
												showAllenNumberCheckBox
													? handleSbmitOfAllenNo
													: handleSubmitOfYesOrNo,
												onSubmitError,
											)}
											variant="contained"
											color="inherit"
											size="medium"
											className="proceed__btn"
										>
                                        PROCEED
										</Button>
									</>
								) : (
									<>
										<img
											src={HappyFace}
											alt="HappyFace"
											width={70}
										/>
										<p style={{ marginTop: "40px" }}>
											<strong>
                                            Thanks for filling the details,
												<br />
                                            We will get back to you
											</strong>
										</p>
									</>
								)}
						</div>
					)}

					{eligibilityResult.EligibilityCode ===
                        EligibilityCodes.REJECTED && (
						<div className="result__failed">
							<img src={SadFace} alt="SadFace" width={70} />
							<p>
								<strong>
                                    Sorry! We could not Pre-Approve you
                                    application
								</strong>
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}

	const formStep1: JSX.Element = (
		<Grid
			container
			spacing={1}
			sx={{ width: windowWidth > 700 ? "34%" : "80%" }}
		>
			<Grid item xs={12} md={6}>
				<Controller
					name={"Mobile"}
					rules={{
						required: {
							value: true,
							message: "Phone number is required",
						},
						pattern: {
							value: mobileRegx,
							message: "Invalid phone number",
						},
					}}
					control={control}
					render={({ field }) => 
					{
						return (
							<TextField
								onChange={field.onChange}
								value={field.value}
								className={`text__field ${
									errors.Mobile ? "text__field__error" : ""
								}`}
								placeholder="Phone Number"
								error={!!errors.Mobile}
								helperText={errors.Mobile?.message}
								InputProps={{
									id: "Mobile",
									style: {
										height: "40px",
										border: "none",
									},
								}}
							/>
						);
					}}
				/>
			</Grid>
		</Grid>
	);

	const formStep2: JSX.Element = (
		<Grid
			container
			spacing={1}
			sx={{ width: windowWidth > 700 ? "70%" : "80%" }}
		>
			<Grid item xs={12} md={6}>
				<Controller
					name={"Name"}
					control={control}
					rules={{
						required: {
							value: true,
							message: t("name_is_required"),
						},
					}}
					render={({ field }) => 
					{
						return (
							<TextField
								onChange={field.onChange}
								value={field.value}
								className={`text__field ${
									errors.Name ? "text__field__error" : ""
								}`}
								placeholder={"Name"}
								error={!!errors.Name}
								helperText={errors.Name?.message}
								InputProps={{
									id: "Name",
									style: {
										height: "40px",
										border: "none",
									},
								}}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<Controller
					name={"Email"}
					control={control}
					rules={{
						required: {
							value: true,
							message: t("email_is_required"),
						},
						pattern: {
							value: emailRegx,
							message: t("invalid_email"),
						},
					}}
					render={({ field }) => 
					{
						return (
							<TextField
								onChange={field.onChange}
								value={field.value}
								className={`text__field ${
									errors.Email ? "text__field__error" : ""
								}`}
								placeholder={"Email"}
								error={!!errors.Email}
								helperText={errors.Email?.message}
								InputProps={{
									id: "Email",
									style: {
										height: "40px",
										border: "none",
									},
								}}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<Controller
					name={"Pan"}
					control={control}
					rules={{
						required: {
							value: true,
							message: t("field_is_required"),
						},
						pattern: {
							value: panRegx,
							message: t("invalid_pan_number!"),
						},
					}}
					render={({ field }) => 
					{
						return (
							<TextField
								onChange={(e) =>
									field.onChange(toUpper(e.target.value))
								}
								value={field.value}
								className={`text__field ${
									errors.Pan ? "text__field__error" : ""
								}`}
								placeholder={"PAN Number"}
								error={!!errors.Pan}
								helperText={errors.Pan?.message}
								InputProps={{
									id: "Pan",
									style: {
										height: "40px",
										border: "none",
									},
								}}
							/>
						);
					}}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<Controller
					name={"DateOfBirth"}
					control={control}
					rules={{
						required: {
							value: true,
							message: t("date_of_birth_required"),
						},
					}}
					defaultValue={""}
					render={({ field }) => 
					{
						return (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DesktopDatePicker
									label={t("date_of_birth")}
									inputFormat="DD/MM/YYYY"
									disableHighlightToday
									value={moment(field.value).format(
										"DD/MM/YYYY",
									)}
									onChange={(date: any) => 
									{
										field.onChange(
											date?.format("DD/MM/YYYY"),
										);
									}}
									disableFuture
									views={["year", "month", "day"]}
									renderInput={(params) => (
										<TextField
											{...params}
											error={!!errors?.DateOfBirth}
											helperText={
												errors?.DateOfBirth?.message
											}
											className={`text__field ${
												errors.DateOfBirth
													? "text__field__error"
													: ""
											}`}
											placeholder={"Date of Birth"}
											InputProps={{
												id: "DateOfBirth",
												style: {
													height: "40px",
													border: "none",
												},
											}}
										/>
									)}
								/>
							</LocalizationProvider>
						);
					}}
				/>
			</Grid>
		</Grid>
	);

	return (
		<div className="container">
			<div className="interest__loans">
				<img
					src={windowWidth > 700 ? IntLoanDSK : IntLoanMOB}
					width={1200}
					style={{
						borderRadius: "15px",
						maxWidth: "100%",
						background: "none",
					}}
				/>
			</div>
			<div className="our__benefits">
				<div className={classes.benefit__item}>
					<img
						src={Benefit1}
						alt="Benefit1"
						width={windowWidth > 700 ? 30 : 19}
						height={windowWidth > 700 ? 40 : 30}
					/>
					<p>Financing Education Since 2017</p>
				</div>
				<div className={classes.benefit__item}>
					<img
						src={Benefit2}
						alt="Benefit2"
						width={windowWidth > 700 ? 30 : 20}
						height={windowWidth > 700 ? 40 : 30}
					/>
					<p>Supporting 2 Lakhs+ Students</p>
				</div>
				<div className={classes.benefit__item}>
					<img
						src={Benefit3}
						alt="Benefit3"
						width={windowWidth > 700 ? 42 : 30}
						height={windowWidth > 700 ? 40 : 30}
					/>
					<p>Working With 500+ Institutes</p>
				</div>
			</div>
			<div className="form__content">
				<h3>Check ur loan eligibility by providing basic details</h3>
				<form
					method="dialog"
					onSubmit={handleSubmit(
						otpSent
							? onVerifyOtp
							: currentStepCompleted === 1
								? onSaveUserDetails
								: onFirstStep,
						onSubmitError,
					)}
					className="form__main"
					autoComplete={"off"}
				>
					{!otpSent && (
						<div className="form__fields">
							{currentStepCompleted === 1
								? showFormStep2 && formStep2
								: formStep1}
						</div>
					)}

					{otpSent && (
						<div className="otp-input__main">
							<Controller
								name={"OTP"}
								control={control}
								rules={{
									required: {
										value: true,
										message: t("otp_is_required"),
									},
								}}
								render={({ field }) => 
								{
									return (
										<div className="otp">
											<TextField
												onChange={field.onChange}
												value={field.value}
												className={`text__field ${
													errors.OTP
														? "text__field__error"
														: ""
												}`}
												variant="outlined"
												placeholder={"Mobile OTP"}
												error={!!errors.OTP}
												helperText={errors.OTP?.message}
												InputProps={{
													id: "OTP",
													style: {
														height: "40px",
														border: "none",
													},
												}}
											/>
											{counter > 0 ? (
												<p>
													{" "}
                                                    Resend otp in {counter} secs
												</p>
											) : (
												<a
													onClick={onResendOTP}
													href="#"
												>
                                                    Resend OTP
												</a>
											)}
										</div>
									);
								}}
							/>
						</div>
					)}

					<div className="footer">
						{!otpSent && (
							<Button
								onClick={handleSubmit(
									currentStepCompleted === 1
										? onSaveUserDetails
										: onFirstStep,
									onSubmitError,
								)}
								variant="contained"
								color="inherit"
								size="medium"
								className="footer__btn"
								startIcon={
									generateOTPLoader ? (
										<CircularProgress
											style={{ color: "white" }}
											size="1rem"
										/>
									) : undefined
								}
							>
								{"SUBMIT"}
							</Button>
						)}

						{otpSent && (
							<>
								<Button
									onClick={handleSubmit(
										onVerifyOtp,
										onSubmitError,
									)}
									variant="contained"
									color="inherit"
									className="footer__btn"
									startIcon={
										props.verifyOTPLoader ? (
											<CircularProgress
												style={{ color: "white" }}
												size="1rem"
											/>
										) : undefined
									}
								>
									{t("proceed")}
								</Button>
							</>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default connector(CustomerFormV2);

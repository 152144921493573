const staticdata = "https://karma.propelld.com/api/static";
const customer = "https://karma.propelld.com/api/customer";
const propelldLoan = "https://karma.loan.propelld.com/";

const baseUrls = {
	customer,
	staticdata,
	propelldLoan,
};

export default baseUrls;

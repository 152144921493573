import { createAction } from "@reduxjs/toolkit";
import { GenerateUserConsentOTPRequest } from "../../services/customer/models/GenerateUserConsent";
import { ResendUserConsentOTPRequest } from "../../services/customer/models/ResendUserConsentOTP";
import { VerifyUserConsentOTPRequest } from "../../services/customer/models/VerifyUserConsent";
import { actionRequest } from "../helper";
import { TriggerPabblyWebhooRequest } from "services/customer/models/TriggerPabblyWebhoo";

export const actions = {
	GENERATE_USER_CONSENT_OTP: "GENERATE_USER_CONSENT_OTP",
	VERIFY_USER_CONSENT_OTP: "VERIFY_USER_CONSENT_OTP",
	RESEND_USER_CONSENT_OTP: "RESEND_USER_CONSENT_OTP",
	TRIGGER_PABBLY_WEBHOOK: "TRIGGER_PABBLY_WEBHOOK",
};

export const generateUserConsentOtp =
    createAction<GenerateUserConsentOTPRequest>(
    	actionRequest(actions.GENERATE_USER_CONSENT_OTP),
    );

export const verifyUserConsentOtp = createAction<VerifyUserConsentOTPRequest>(
	actionRequest(actions.VERIFY_USER_CONSENT_OTP),
);

export const resendUserConsentOtp = createAction<ResendUserConsentOTPRequest>(
	actionRequest(actions.RESEND_USER_CONSENT_OTP),
);

export const triggerPabblyWebhook = createAction<TriggerPabblyWebhooRequest>(
	actionRequest(actions.TRIGGER_PABBLY_WEBHOOK),
);

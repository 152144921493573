const staticdata = "http://localhost:8080/static";
const customer = "http://localhost:8080/customer";
const propelldLoan = "http://localhost:3010";

const baseUrls = {
	customer,
	staticdata,
	propelldLoan,
};

export default baseUrls;

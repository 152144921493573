import i18n from "i18next";
// import queryString from "query-string";
import { initReactI18next } from "react-i18next";

import en_US from "./locales/en_US.json";
import kn_IN from "./locales/kn_IN.json";
import hi_IN from "./locales/hi_IN.json";

// let lng = "en_US";
// const languageFromQueryParams = queryString.parse(window.location.search);
// if (languageFromQueryParams?.lng)
// {
// 	lng = languageFromQueryParams.lng.toString();
// }

i18n.use(initReactI18next).init({
	lng: "en_US",
	fallbackLng: "en_US",
	resources: {
		en_US: { translation: en_US },
		kn_IN: { translation: kn_IN },
		hi_IN: { translation: hi_IN },
	},
	react: {
		defaultTransParent: "span",
	},
});

export default i18n;

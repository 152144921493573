import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/home/HomePage";

import Page404 from "../pages/Page404";

export const siteMap = {
	HomePage: {
		title: "Home",
		path: "/",
		description: "My home page",
	},
};

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
const MainRouter = () => (
	<Routes>
		<Route path={siteMap.HomePage.path} element={<HomePage />} />
		<Route element={<Page404 />} />
	</Routes>
);

export default MainRouter;

import React, { useEffect, useState } from "react";

import PropelldLogo from "./PropelldLogo";
import DesktopBackground from "../../assets/background_desktop.svg";
import MobileBackground from "../../assets/background_mob.svg";
import CustomerFormV2 from "./CustomerFormV2";
import "./style.scss";

const HomePage = () => 
{
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => 
	{
		// Function to update windowWidth when the window is resized
		const handleResize = () => 
		{
			setWindowWidth(window.innerWidth);
		};

		// Attach the event listener when the component mounts
		window.addEventListener("resize", handleResize);

		// Remove the event listener when the component unmounts
		return () => 
		{
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="main">
			<div className="header__logos">
				<PropelldLogo />
			</div>
			<div className="background__img">
				<img
					src={
						windowWidth > 700 ? DesktopBackground : MobileBackground
					}
					alt="DesktopBackground"
					style={{ display: "block", maxHeight: "100%" }}
					height={"50%"}
					width={"100%"}
				/>
			</div>

			<>
				<CustomerFormV2 />
			</>
		</div>
	);
};

export default HomePage;

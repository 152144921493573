import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={180}
		height={30}
		viewBox="0 0 1521 268"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M571.907 145.152c0-41.76-29.376-73.728-69.408-73.728-20.736 0-38.88 8.352-49.248 23.04V74.592h-28.224V267.84h30.24v-69.12c11.232 12.96 27.936 20.448 47.232 20.448 40.032 0 69.408-31.68 69.408-74.016Zm-31.104.288c0 27.072-19.008 46.08-43.2 46.08-22.176 0-42.624-15.264-42.624-46.368 0-30.816 20.448-46.08 42.624-46.08 24.48 0 43.2 19.296 43.2 46.368ZM687.843 74.592c-3.456-.864-7.2-1.44-11.52-1.44-16.128 0-31.104 7.2-41.184 19.872V74.592h-28.224V216h30.24v-75.456c0-25.056 17.568-39.168 41.472-39.168h9.216V74.592ZM703.374 145.152c-.864 40.032 33.984 74.88 74.592 74.016 41.184.864 76.032-33.984 75.168-74.016.864-40.032-33.984-74.592-75.168-73.728-40.608-.576-75.456 33.12-74.592 73.728Zm118.656.288c0 25.632-19.008 45.792-44.064 45.792-24.768 0-43.776-19.584-43.776-45.792 0-26.208 19.008-46.368 44.064-46.368 24.48 0 43.776 20.16 43.776 46.368ZM1034.88 145.152c0-41.76-29.37-73.728-69.406-73.728-20.736 0-38.88 8.352-49.248 23.04V74.592h-28.224V267.84h30.24v-69.12c11.232 12.96 27.936 20.448 47.232 20.448 40.036 0 69.406-31.68 69.406-74.016Zm-31.1.288c0 27.072-19.01 46.08-43.202 46.08-22.176 0-42.624-15.264-42.624-46.368 0-30.816 20.448-46.08 42.624-46.08 24.48 0 43.202 19.296 43.202 46.368ZM1197.19 155.232c1.44-6.624 2.3-12.96 2.3-19.008 0-36.864-27.94-64.8-65.38-64.8-40.03-.864-74.01 33.408-73.15 74.016 0 41.76 32.83 73.728 76.03 73.728 21.6 0 40.32-7.488 56.45-22.752l-14.4-20.736c-12.67 11.232-26.49 16.704-41.18 16.704-24.19 0-42.34-14.112-46.08-37.152h105.41Zm-104.84-23.616c4.61-21.024 20.74-34.56 40.61-34.56s36 12.96 36.29 34.56h-76.9ZM1234.34 216h30.24V0h-30.24v216ZM1308.51 216h30.24V0h-30.24v216ZM1490.1 0v91.872c-10.65-12.96-27.65-20.448-47.23-20.448-39.17-.864-69.98 32.832-69.12 74.016 0 41.76 29.38 73.728 69.12 73.728 21.02 0 39.17-8.64 49.54-23.328V216h28.22V0h-30.53Zm.58 145.44c0 30.816-20.45 46.08-42.63 46.08-25.05 0-43.48-19.296-43.48-46.368 0-27.072 19.29-46.08 43.48-46.08 21.89 0 42.63 15.84 42.63 46.368Z"
			fill="#fff"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m0 217.999 56.663-55.495 57.187 55.495H0Z"
			fill="#fff"
			fillOpacity={0.95}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m56.662 162.505 57.188-56.009 56.662 56.009H56.662Z"
			fill="#fff"
			fillOpacity={0.75}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M113.852 106.495 170.514 51l56.663 55.495H113.852Z"
			fill="#fff"
			fillOpacity={0.55}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m170.512 162.504-56.662 55.495-57.188-55.495h113.85Z"
			fill="#fff"
			fillOpacity={0.85}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m227.177 106.496-56.663 56.009-56.662-56.009h113.325Z"
			fill="#fff"
			fillOpacity={0.65}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m227.176 106.496-56.662 56.009L227.176 218h113.851L227.176 106.496Z"
			fill="#fff"
			fillOpacity={0.55}
		/>
	</svg>
);

export default SvgComponent;

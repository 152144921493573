import { apiRequestHandler, getMiddleware } from "../middlewareHelper";
import { actions as types } from "./actions";
import service from "../../services/customer";
import { toast } from "react-toastify";

// export const fetchPincodeDetailsFlow = getMiddleware({
// 	actionType: types.FETCH_PINCODE_DETAILS,
// 	onRequest: apiRequestHandler(service.getPincodeDetailsForApplication),
// });

export const generateUserConsentOtpFlow = getMiddleware({
	actionType: types.GENERATE_USER_CONSENT_OTP,
	onRequest: apiRequestHandler(service.generateUserConsentOTP),
});

export const resendUserConsentOtpFlow = getMiddleware({
	actionType: types.RESEND_USER_CONSENT_OTP,
	onRequest: apiRequestHandler(service.resendUserConsentOTP),
	onSuccess: async () => 
	{
		toast("Resend SMS Successful!", {
			type: "success",
			position: "top-right",
		});
	},
});

export const verifyUserConsentOtpFlow = getMiddleware({
	actionType: types.VERIFY_USER_CONSENT_OTP,
	onRequest: apiRequestHandler(service.verifyUserConsentOTP),
});

export const triggerPabblyWebhooFlow = getMiddleware({
	actionType: types.TRIGGER_PABBLY_WEBHOOK,
	onRequest: apiRequestHandler(service.triggerPabblyWebhook),
});

export default [
	// fetchPincodeDetailsFlow,
	generateUserConsentOtpFlow,
	resendUserConsentOtpFlow,
	verifyUserConsentOtpFlow,
	triggerPabblyWebhooFlow,
];
